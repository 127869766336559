<template>
    <div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>院区设置</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-top: 20px">
            <div class="search">
                <el-input v-model="keywords" size="small" style="width: 20%" clearable></el-input>
                <el-button type="primary" plain size="small" style="margin-left:10px " @click="handleSearch">检索</el-button>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleAdd">添加院区</el-button>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index" width="50" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="warning" icon="el-icon-edit"></el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>

            <div style="text-align: center;margin: 20px" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog title="设置" :visible.sync="dialogVisible" width="40%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="名称">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="form.contact" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="营业时间">
                    <el-input v-model="form.worktime" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="form.address" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="照片">
                    <el-upload
                        class="avatar-uploader"
                        action="/api/upload/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="form.avatar" :src="form.avatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="GPS坐标">
                    <el-input v-model="form.location" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="顺序">
                    <el-input-number v-model="form.sort" style="width:80%"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '名称', field: 'name', width: ''},
                        {title: '联系方式', field: 'contact', width: ''},
                        {title: '地址', field: 'address', width: ''},
                        {title: '营业时间', field: 'worktime', width: ''},
                        {title: '排序', field: 'sort', width: '80'},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                keywords: '',
                dialogVisible: false,
                form: {
                    name: '',
                    contact: '',
                    worktime: '',
                    avatar: '',
                    address: '',
                    location: '',
                    sort: 0,
                },
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            handleAvatarSuccess(res, file) {
                // console.log(res)
                // this.form.avatar = URL.createObjectURL(file.raw);
                this.form.avatar = res
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {name: '', contact: '', address:'', location: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.dialogVisible = true
                this.form = row
            },
            async handleDel(idx, row){
                const resp = await this.$http.delete(`/mingde/hospital/${row.ID}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async addSubmit() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/hospital/${this.form.id}` : `/mingde/hospital/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData() {
                const resp = await this.$http.get(`/mingde/hospital/?keywords=${this.keywords}`)
                this.table.data = resp.data.data
            },
            handleSearch() {
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }
    .avatar {
        max-width: 300px;
    }
</style>
